const AcceptTermsAndPrivacy = () => {
  return (
    <div style={{ margin: '0.5em 0' }}>
      By creating an account or signing in you agree to our <a href={`/terms`}>Terms and Conditions</a>
      {' and '}
      <a href={`/privacy`}>Privacy Policy</a>
    </div>
  )
}

export default AcceptTermsAndPrivacy
